<template>
  <ItemEditTemplate ref=template v-bind="{resource, item, loading, creating}" v-on="{save,cancel}" v-if="item">
    <div class="row row-cols-1 row-cols-md-2 mt-4">
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i :class="['bx',resource.icon]"></i>
            {{ $t("batch_items.label") }}
          </template>

          <div class="row row-cols-1 row-cols-xl-2">
            <div class="col">
              <form-input
                label="common.first_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.first_name"
              />
            </div>
            <div class="col">
              <form-input
                label="common.last_name" 
                icon="bx-purchase-tag"
                rules="required" 
                v-model="item.last_name"
              />
            </div>
          </div>

          <PhoneInput required label="common.phone" v-model="item" />

          <form-input
            type="email"
            name="email"
            label="common.email" 
            icon="bx-envelope"
            rules="email"
            v-model="item.email"
          />

        </b-card>
      </div>
      <div class="col mb-4">
        <b-card>
          <template #header>
            <i class="bx bxs-truck"></i>
            {{ $t("shipments.label") }}
          </template>

          <form-input 
            label="shipments.external-reference" 
            icon="bxs-purchase-tag"
            rules="required"
            v-model="item.external_reference_id"
          />

          <form-input
            v-if="parent"
            type="item" 
            label="batch_items.pickup-location" 
            icon="bx-map" 
            v-model="item.dropoff_location_id"
            resource-name="locations"
            :description="$t('batch_items.pickup-location-desc')"
            :scope="dropoffScope"
            :clearable="true"
          />

        </b-card>
        <b-card v-if="creditCards">
          <template #header> 
            <i class="bx bxs-credit-card" />
            {{$t('batch_items.credit-card')}}
          </template>
          <form-input
            type="date"
            label="batch_items.expiry-date" 
            icon="bx-calendar"
            rules="required"
            v-model="item.offset_date"
          />
        </b-card>
      </div>
    </div>
  </ItemEditTemplate>
</template>

<script>
import ItemEdit from "@/components/ItemEdit";
import ItemEditTemplate from "@/components/ItemEditTemplate";
import FormInput from "@/components/Form/Input";
import PhoneInput from "@/components/Form/PhoneInput";
import { FEATURE_BATCH_CREDIT_CARDS } from '@/resources/Merchant';

export default {
  extends: ItemEdit,
  components: { ItemEditTemplate, FormInput, PhoneInput },
  computed: {
    creditCards() {
      return !!(this.parent?.features & FEATURE_BATCH_CREDIT_CARDS)
    },
    locales() {
      return this.$i18n.availableLocales.reduce((c,v) => (c.push({code:v,label:`locale.${v}`}),c),[])
    },
    dropoffScope() {
      let scopes = [
        ['dropoff',this.parent.merchant_id],
        'active'
      ]

      // if(this.containsOversized) {
      //   scopes.push(['class',CLASS_PUDO])
      // }

      return scopes
    },
  }
};
</script>